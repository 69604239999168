<template>
  <div class="contain-select-width">
    <v-select
      :value="value"
      :items="items"
      dense
      outlined
      hide-details
      :label="label"
      v-on="$listeners"
      v-bind="$attrs"
    ></v-select>
  </div>
</template>

<script>
// Import the currency map
import currencySymbolMap from "@/helpers/currencySymbolMap"

// Export the SFC
export default {
  // Name of the component
  name: "CurrencySelector",

  // Whether to accept incoming attributes
  inheritAttrs: false,

  // Accept incoming data from parent
  props: {
    label: {
      type: String,
      required: false,
      default: "Currency"
    },

    value: {
      type: String,
      required: false,
      default: "inr"
    },
  },

  data: () => ({
    items: [
      {
        text: `${currencySymbolMap.inr} Indian Rupee`,
        value: "inr",
      },
      {
        text: `${currencySymbolMap.usd} US Dollar`,
        value: "usd",
      },
      {
        text: `${currencySymbolMap.myr} Malaysian Ringgit`,
        value: "myr",
      },
      {
        text: `${currencySymbolMap.sgd} Singapore Dollar`,
        value: "sgd",
      },
      {
        text: `${currencySymbolMap.idr} Indonesian Rupiah`,
        value: "idr",
      },
      {
        text: `${currencySymbolMap.vnd} Vietnamese Dong`,
        value: "vnd",
      },
      {
        text: `${currencySymbolMap.aed} UAE Dirham`,
        value: "aed",
      },
    ]
  }),

  mounted() {
    this.$emit('input', this.value)
  }
}
</script>

<style lang="stylus" scoped>
.contain-select-width
  min-width 17em
  width 17em
</style>
