import { render, staticRenderFns } from "./CurrencySelector.vue?vue&type=template&id=0a924980&scoped=true"
import script from "./CurrencySelector.vue?vue&type=script&lang=js"
export * from "./CurrencySelector.vue?vue&type=script&lang=js"
import style0 from "./CurrencySelector.vue?vue&type=style&index=0&id=0a924980&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a924980",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
